import React from 'react'
const Contact = () => {
  return (
    <div className='container pt-3 ' >
      <section id="contact" className="padd-section wow fadeInUp p-4" style={{borderRadius:"20px"}}>

<div className="container">
  <div className="contactus section-title text-center">
    <h3 style={{color:"white"}}>Contact Us</h3>
    <p className="separator">LAAS Illam, 10 Classic Garden, Ulaganeri, Madurai - 625107</p>
  </div>
</div>
    <div className='d-flex justify-content-between'>
            <div className="col-lg-3 col-md-4">

<div className="info">
  <div>
    <i className="fa fa-map-marker"></i>
    <p>LAAS Illam, 10 Classic Garden,<br/>Ulaganeri, Madurai - 625107</p>
  </div>

  <div className="email">
    <i className="fa fa-envelope"></i>
    <p>lassillam@gmail.com</p>
  </div>

  <div>
    <i className="fa fa-phone"></i>
    <p>+919876543210</p>
  </div>

</div>

<div className="social-links">
  <a href="#" className="p-2 twitter"><i className="fab fa-twitter"></i></a>
  <a href="#" className="p-2 facebook"><i className="fab fa-facebook"></i></a>
  <a href="#" className="p-2 instagram"><i className="fab fa-instagram"></i></a>
  <a href="#" className="p-2 google-plus"><i className="fab fa-google-plus"></i></a>
  <a href="#" className="p-2 linkedin"><i className="fab fa-linkedin"></i></a>
</div>

</div>

 <div className="col-lg-5 col-md-8">
<div className="form">
  <div id="sendmessage">Your message has been sent. Thank you!</div>
  <div id="errormessage"></div>
  <form action="" method="post" role="form" className="contactForm">
    <div className="form-group" >
      <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
      <div className="validation"></div>
    </div>
    <div className="form-group">
      <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
      <div className="validation"></div>
    </div>
    <div className="form-group">
      <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
      <div className="validation"></div>
    </div>
    <div className="form-group">
      <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
      <div className="validation"></div>
    </div>
    <div className="text-center"><button type="submit">Send Message</button></div>
  </form>
</div>
</div>

        
      </div>
      </section>
    </div>
  )
}
export default Contact;