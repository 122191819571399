import React from 'react';

const Gallery = () => {
  return (
    <div className="container">
    
        <h1 className="text-center" >
          Gallery
        </h1>

        <div>

        </div>
        
    </div>
  )
}
export default Gallery;