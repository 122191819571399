import React from 'react'
import SliderMain from '../../Components/Slider/SliderMain';
import courses from '../../images/images/course-banner.png';
import levels from '../../images/images/course-levels-test.png';
import world from '../../images/images/rotatingearth.gif';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Contact from '../Contact/Contact';
const Home = () => {
  return (
    <div   >
    <SliderMain/>
   {/* <h1 className="text-center" style={{paddingTop: "0px"}}>
      Home
  </h1> */}

  <AnimationOnScroll animateIn="animate__fadeInRightBig">


    <div className='courses-main'>
      <img className='img-fluid rounded' src={courses} alt="Courses Banner" />
    </div>
    </AnimationOnScroll>
    <AnimationOnScroll animateIn="animate__fadeInLeftBig">
      <div className='course-level-section'>
      <h1 className="header-txt text-center " style={{paddingTop: "0%", color:"yellow"}}>

Creative World offers various Levels of Art programmes
</h1>
    <div className='d-flex flex-column text-center container' >
    <div className='courses-main'>
      <img className='img-world img-fluid' src={world} alt="Courses world" style={{width:"28%"}}/>
    </div>
    <div className='courses-main courses-banner-img pt-5' >
    <img className='img-fluid rounded' src={levels} alt="Courses Banner" style={{width:"1200px"}}/>
    </div>
    </div>
      </div>
   
    </AnimationOnScroll>
  
    
   {/*} <div className='courses-main container p-4'>
       

    <div className='list-sty'>
        Primary Level
      </div>
      <div className='list-sty'>
        Junior Diploma
      </div>
      <div className='list-sty'>
        Secondary Standard
      </div>
      <div className='list-sty'>
        Senior Diploma
      </div>
      <div className='list-sty'>
        Graduate Level
      </div>
      <div className='list-sty'>
       Post Graduate Level
      </div>
    </div>
*/}
    <AnimationOnScroll animateIn="animate__fadeInRightBig">
       <Contact />
    </AnimationOnScroll>
   
  </div>
  )
}
export default Home;