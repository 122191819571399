import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Courses from './pages/Courses/Courses';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import Testimonial from './pages/Testimonial/Testimonial';
import Navbar from './Components/Navbar/Navbar';
import OilPainting from './pages/Courses/Pages/OilPainting';
import Gallery from './pages/Gallery/Gallery';
/*Fonts */
import '../src/App.css';
import "./Fonts/Freehand.ttf";
import "./Fonts/BRLNSDB.TTF";
import "./Fonts/MisterEarlBTRegular.TTF";
import "./Fonts/BernhandTango.ttf";
import "./Fonts/Calligraph421BT.TTF";
import "./Fonts/domboldBT.ttf";
import "./Fonts/GilSansMTCondBold.TTF";
import "./Fonts/GilSansMTCondensed.TTF";
import "./Fonts/HARNGTON.TTF";
import "./Fonts/HoboStd.otf";
import "./Fonts/JohnHandyLET.TTF";
import "./Fonts/ParkAvenue.TTF";
import "./Fonts/TektonPro-Bold.otf";
import "./Fonts/TektonPro-BoldCond.otf";

const App = () => {
  return (
   <Router>
    <Navbar/>
  
    <main>
      <Switch>
        <Route path="/" exact>
          <Home/>
        </Route>
        <Route path="/courses" exact>
          <Courses/>
        </Route>
        <Route path="/gallery" exact>
          <Gallery/>
        </Route>
        <Route path="/testimonial" exact>
          <Testimonial/>
        </Route>
        <Route path="/contact" exact>
          <Contact/>
        </Route>
        <Route path="/courses/oilpainting" exact>
          <OilPainting/>
        </Route>
        <Redirect to="/" />
      </Switch>
    </main>
   </Router>
  );
}

export default App;
