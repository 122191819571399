import React,{useState} from 'react';
import img1 from '../../images/images/oil.jpg';
import img2 from '../../images/images/pencil.jpg';
import img3 from '../../images/images/watercolor.jpg';
import img4 from '../../images/images/glass.jpeg';
import img5 from '../../images/images/gpaint.jpg';
import img6 from '../../images/images/fabric.jpg';
import img7 from '../../images/images/muralart.jpg';
import {Container, Row, Tab, Tabs } from 'react-bootstrap';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';


import {Nav, NavItem, NavLink, TabContent, TabPane, Col, Card, CardTitle, CardText, Button} from 'reactstrap';
import CoursesNav from './CoursesNav';
import OilPainting from './Pages/OilPainting';
export default function Courses() {
var Carousel = require('react-responsive-carousel').Carousel;
const [tabValue, setTabValue]=useState("Home")
  const componentRef = React.useRef(null);
  const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div >
 
 <h1 className="header-txt text-center " style={{paddingTop: "0%"}}>

Courses We Offer!
</h1>

<div>
  <div className='container'>
    <Accordion  flush open={open} toggle={toggle}>
        <AccordionItem >
          <AccordionHeader targetId="1">
            <div className='d-flex justify-content-between p-2'>
              <div>
                Oil Painting:  Oil paints are primarily used on canvas or thicker boards
              </div>
               <div>
                <spam><i className='fa fa-angle-down'></i></spam>
               </div>
            </div>
           
            </AccordionHeader>
          <AccordionBody accordionId="1">
          <OilPainting/>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">
          <div className='d-flex justify-content-between p-2'>
              <div>
                Pencil Shading
              </div>
               <div>
                <spam><i className='fa fa-angle-down'></i></spam>
               </div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="2">
            <strong>This is the second item&#39;s accordion body.</strong>
            You can modify any of this with custom CSS or overriding our default
            variables. It&#39;s also worth noting that just about any HTML can
            go within the <code>.accordion-body</code>, though the transition
            does limit overflow.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">
          <div className='d-flex justify-content-between p-2'>
              <div>
                Water Colouring
              </div>
               <div>
                <spam><i className='fa fa-angle-down'></i></spam>
               </div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="3">
            <strong>This is the third item&#39;s accordion body.</strong>
            You can modify any of this with custom CSS or overriding our default
            variables. It&#39;s also worth noting that just about any HTML can
            go within the <code>.accordion-body</code>, though the transition
            does limit overflow.
          </AccordionBody>
        </AccordionItem>
      </Accordion>
  </div>
      
    </div>
{/*
<Container className='card py-4' style={{backgroundColor:"black"}}>
  <Row className='justify-content-center'>
    <Tabs justify variant='' className='tab-main p-0' >         
            <Tab tabClassName='tab-view'  eventKey="Oil" title="Oil Painting" >
        <OilPainting/>
            </Tab>
         
     
      <Tab tabClassName='tab-view' eventKey="Pencil" title="Pencil Shading">
        sadfsdfsdf
      </Tab>
      <Tab tabClassName='tab-view' eventKey="Water" title="Water Colouring">
    asdfsadfsdf
      </Tab>
      <Tab tabClassName='tab-view' eventKey="Acrylic" title="Acrylic Painting">
        asdfasdf
        </Tab>
        <Tab tabClassName='tab-view' eventKey="Fabric" title="Fabric Painting">
          e45rghde5rt
        </Tab>
        <Tab tabClassName='tab-view' eventKey="Glass" title="Glass Painting">
      
        </Tab>
        <Tab tabClassName='tab-view' eventKey="Mural" title="Mural Art">
      
        </Tab>
    </Tabs>
  </Row>
</Container>
      
  */}
   
      </div>


  
  )
}

