
import React, { Component } from 'react'
import slider1 from '../../images/images/slider1.jpg';
import slider2 from '../../images/images/slider2.jpg';
import slider3 from '../../images/images/slider3.jpg';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../Slider/slider.css";
import { Carousel } from 'react-responsive-carousel';


const items = [
  {
    src: 'https://picsum.photos/id/123/1200/400',
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 1,
  },
  {
    src: 'https://picsum.photos/id/456/1200/400',
    altText: 'Slide 2',
    caption: 'Slide 2',
    key: 2,
  },
  {
    src: 'https://picsum.photos/id/678/1200/400',
    altText: 'Slide 3',
    caption: 'Slide 3',
    key: 3,
  },
];


export default class SliderMain extends Component {
  render() {
    return <div  style={{paddingTop: '0px'}} >

<Carousel className='navbar-bg' autoPlay={true} showThumbs={false} infiniteLoop={true}  >
                <div >
                    <img className='img-fluid' src={slider1} alt='slider' />
                    <div className='slider-cap-wrap' >
                        
                        
           </div>
            </div>
                <div>
                    <img src={slider2} alt='slider'/>
               {/*   <div className='slider-cap-wrap'  >
                        
                 <div className="card container caption" >
                      <h2>SITE &nbsp; LAND DEVELOPMENTS</h2>
                      <p>Develop your existing land with us and build into residential apartments, 
                          commercial spaces and much more</p>
                      
                  </div>  
                  <button className='btn-caption' >Know More</button>
  </div> */}
                   </div>
                <div>
                    <img src={slider3}  alt='slider' />
                   
                      </div>
                    
                    
            </Carousel>
       {/*   <Carousel className='navbar-mobile' showThumbs={false} autoPlay={true} showIndicators={false} infiniteLoop={true}  showStatus={false}  >
                <div>
                    <img src={mslider1} alt='slider' />
                  </div>
                <div>
                    <img src={mslider2} alt='slider'/>
                  
                   </div>
                <div>
                    <img src={mslider3}  alt='slider' />
                      </div>
                      <div>
                    <img src={mslider4} alt='slider'/>
                  
                   </div>
                <div>
                    <img src={mslider5}  alt='slider' />
                      </div>
  </Carousel> */}
    </div>;
  }
}




