import React from 'react';
import img1 from '../../../images/images/oil.jpg';
import img2 from '../../../images/images/pencil.jpg';
import img3 from '../../../images/images/watercolor.jpg';
import img4 from '../../../images/images/glass.jpeg';
import img5 from '../../../images/images/gpaint.jpg';
import img6 from '../../../images/images/fabric.jpg';
import img7 from '../../../images/images/muralart.jpg';
import {Row, Col}  from 'react-bootstrap';
var Carousel = require('react-responsive-carousel').Carousel;
const OilPainting = () => {
  return (
    <div   >
  
  
   
    <div >
  



  <Row>
    <Col > 
    <div > 
 <Carousel showArrows={true} >
               
                <div >
                <img src={img1} alt="test" style={{maxHeight:"320px"}}/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                <img src={img2} alt="test" style={{maxHeight:"320px"}} />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                <img src={img3} alt="test" style={{maxHeight:"320px"}} />
                    <p className="legend">Legend 3</p>
                </div>
                <div>
                <img src={img4} alt="test" style={{maxHeight:"320px"}} />
                    <p className="legend">Legend 4</p>
                </div>
                <div>
                <img src={img5} alt="test" style={{maxHeight:"320px"}} />
                    <p className="legend">Legend 5</p>
                </div>
                <div>
                <img src={img6} alt="test" style={{maxHeight:"350px"}} />
                    <p className="legend">Legend 6</p>
                </div>
                <div>
                <img src={img7} alt="test" style={{maxHeight:"350px"}} />
                    <p className="legend">Legend 6</p>
                </div>
               
            </Carousel> 
            </div>
    </Col>
    <Col>
    <div className='courses-info p-2' style={{margin:"20px"}}>
   <h4 style={{fontSize:"16px"}}>
    Oil Painting (Certified Course)
    </h4> 
    <div className='courses-info-text' >
      <li>Different techniques of oil paint.
   </li>
<li>Knowledge on different tool to be used in oil painting.
  </li>
<li>How to preserve oil painting for years.
  </li>
    </div>
{/*
   
<img className='p-2' src={img1} alt="test" style={{width:"120px", borderRadius:"20px"}} />

<img className='p-2' src={img1} alt="test" style={{width:"120px", borderRadius:"20px"}} />

<img className='p-2' src={img1} alt="test" style={{width:"120px", borderRadius:"20px"}} />
*/}
      </div> 
    </Col>
    </Row>
 
  
  </div>
  
</div>

     
  
  )
}
export default OilPainting;